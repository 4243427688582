<template>
  <div style="background: #ebedf0;color: #000;overflow: hidden">
    <div class="bianti_a">
      <van-icon @click="back" class="fanhui" name="arrow-left" />
      <span style="vertical-align: middle">订单详情</span>
    </div>

    <div class="biao_ti" style="padding-top:46px;  position: relative;background: #ebedf0;">
      <img class="beijintu" v-if="list.status == 0 || list.status == 1" src="../../assets/img/dengdai.png" alt />
      <img class="beijintu" v-if="list.status == -1" src="../../assets/img/quxiao.png" alt />
      <img class="beijintu" v-if="list.status == 3" src="../../assets/img/wancheng.png" alt />
      <div class="zidong">
        <div class="zidong_a">
          <span v-if="list.status == 1">待预约</span>
          <span v-if="list.status == 0">等待您付款</span>
          <span v-if="list.status == -1">订单已取消</span>
          <span v-if="list.status == 3">订单已完成</span>
        </div>
        <div class="zidong_b" v-if="list.status == 0">还剩{{stayHour}}时{{stayMin}}分自动取消订单</div>
      </div>

      <div class="peisong">
        <div class="lis_h1">
          <img class="menbiao left" src="../../assets/img/menbiao.jpg" alt />
          <span class="song left">配送至门店</span>
          <img class="right daohan" @click="daohang" src="../../assets/img/daohan.jpg" alt />
          <!-- <span class="rigth"></span> -->
        </div>
        <div class="lis_h2">{{list.garageName}}</div>
        <div class="lis_h3">
          <img class="order_img left" v-if="shangjia.doorPic" :src="shangjia.doorPic" alt />
          <img class="order_img left" v-else src="../../assets/img/mendian.png" alt />
          <div class="left order_name">
            <div class="biaoti_ord">{{list.areaDetail}}</div>
            <div class="jiance" v-if="list.carNo">{{list.carNo}}</div>
            <div style="width: 77px;height: 22px; margin: 6px 0;" v-else></div>
            <div class="jianshu">
              <span class style="color: #999;">{{list.receiver}}</span>
              <span style="margin-left: 25px;">{{list.tel}}</span>
            </div>
          </div>
        </div>
        <div class="lis_h4">
          <img class="menbiao left" src="../../assets/img/chebiao.jpg" alt />
          <span class="song left">{{list.carModel}}</span>
          <span class="yyyue" v-if="list.status == 2">已预约</span>
        </div>
        <!-- <div class="lis_h3" style="color: #666;">2008款 2.0L 无极 标准版</div> -->
      </div>

      <div class="peisong2">
        <div class="lis_h1" v-if="list.goods == []">
          <span class="song2 left">商品</span>
          <img class="anzhuang left" src="../../assets/img/anzhuang.jpg" alt />
        </div>
        <div class="liss_c" v-for="(item,idx) in list.goods" :key="idx">
          <img class="order_img left" :src="item.attrImg" alt />
          <div class="left order_name">
            <div class="yichu_c">{{item.title}}</div>
            <div class="jianshu2">
              <span style="color: #000;font-weight: bold;">￥{{item.price}}</span>
              <span class="right" style="margin-left: 25px;color: #666;">x{{item.goodsNum}}</span>
            </div>
          </div>
        </div>
        <div class="lis_h4" v-if="list.services != ''">
          <span class="song left">服务</span>
          <!-- <span class="rigth"></span> -->
        </div>
        <div class="liss_c" v-for="(item,index) in list.services" :key="'c'+index">
          <img class="order_img left" src="../../assets/img/sp.jpg" alt />
          <div class="left order_name">
            <div class="yichu_c">{{item.title}}</div>
            <div class="jianshu2">
              <span style="color: #000;font-weight: bold;">￥{{item.price}}</span>
              <span class="right" style="margin-left: 25px;color: #666;">x{{item.goodsNum}}</span>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="dingdan_bianhao">
      <div class="din_a">
        <span class="ff_c">订单编号：</span>
        <span>{{list.orderNumber}}</span>
        <van-button plain class="copi" type="default" size="mini" @click="copy">复制</van-button>
      </div>
      <div class="din_a">
        <span class="ff_c">订单时间：</span>
        <span>{{list.createTime}}</span>
      </div>
      <div class="din_a">
        <span class="ff_c">支付方式：</span>
        <span>{{list.orderPaySetting == 1?'线上支付':'线下支付'}}</span>
      </div>
      <div class="din_a">
        <span class="ff_c">配送方式：</span>
        <span>暂无</span>
      </div>
      <div class="din_a xian" style="padding-bottom: 15px">
        <span class="ff_c left">支付状态：</span>
        <span>{{list.payStatus == 0?'未付款':'已付款'}}</span>
      </div>

      <div class="erdu" style="padding-top: 15px">
        <span class="ff_c">商品总额</span>
        <span class="right">￥{{list.orderTotal}}</span>
      </div>
      <div class="erdu">
        <span class="ff_c left">运费</span>
        <span class="right">+￥{{list.shippingTotal}}</span>
      </div>
      <div class="erdu">
        <span class="ff_c left">优惠</span>
        <span class="right">-￥{{list.couponPrice}}</span>
      </div>
      <div class="erdu" style="padding: 15px 0;border-bottom: 1px solid #f5f5f5;">
        <span class="ff_c left">实付款</span>
        <span class="right moni">￥{{list.actualTotal}}</span>
      </div>
      <div class="lianxi">
        <div class="left wis">
          <van-icon style="vertical-align: middle" name="service-o" />
          <span style="vertical-align: middle">在线客服</span>
        </div>
        <div class="left wis">
          <van-icon style="vertical-align: middle" name="phone-o" />
          <span style="vertical-align: middle">
            <a :href="'tel:'+shangjia.tel1">联系门店</a>
          </span>
        </div>
      </div>
    </div>
    <div class="mad" v-if="list.payStatus == '1'">
      <div class="h1_c">订单二维码</div>
      <div class="h1_d">让门店扫一扫，节省您与门店对接的时间</div>
      <!-- <div class="qy_code_wrapper" ref="qrCode" xss="removed"></div> -->
      <div class="qy_code_wrapper">
        <vue-qr :text="downloadData.url" :margin="10" colorDark="#000" colorLight="#fff" :dotScale="1" :logoSrc="downloadData.icon" :logoScale="0.2" :size="300"></vue-qr>
      </div>
    </div>
    <div style="height: 55px;"></div>

    <div class="bott" v-if="list.status == 0 || list.status == 1 || list.status == 2">
      <div class="right zhifu" @click="zhifupay" v-preventReClick v-if="list.status == '0' && type == '1'">立即支付</div>
      <div class="right zhifu" @click="miaoshatijiao" v-preventReClick v-if="list.status == '0' && type == '2'">立即支付</div>
      <div class="right quxiao" v-if="list.status == '0'" @click="quxiao">取消订单</div>
      <div class="right zhifu" v-if="list.status == '1'" @click="yuyue">预约</div>
      <div class="right zhifu" v-if="list.status == '2'" @click="QXyuyue">取消预约</div>
    </div>
  </div>
</template>

<script>
import QRCode from "qrcodejs2";
import orderModel from "../../api/order";
import userModel from "../../api/user";
import utils from "../../assets/utils";
import preventReClick from "../../assets/preventReClick";

import vueQr from "vue-qr";

// import preventBack from "vue-prevent-browser-back"; //组件内单独引入
export default {
  // mixins: [preventBack], //注入
  components: {
    vueQr,
  },
  //   url:
  //   "http://customertest.car-posthouse.cn/quanbu_details.html?id=" +
  //   this.id +
  //   "&token=" +
  //   localStorage.getItem("Authorization"), //需要转化成二维码的网址
  // icon: require("../../assets/img/1.png") //二维码中间的图片,可以不设置
  data() {
    return {
      list: {},
      id: "",
      shangjia: JSON.parse(sessionStorage.getItem("shangjia")),
      status: "",
      row: "",
      isok: false,
      type: "",
      stayHour: "",
      number: "",
      yyisok: "",
      downloadData: {},
      isWxMini: "",
      stayMin: "",
    };
  },
  methods: {
    zhifupay() {
      var _this = this;
      var lis = {
        id: this.list.id,
        orderNumber: this.list.orderNumber,
        totalMoney: this.list.orderTotal,
        type: this.type,
        gid: this.list.gid,
      };
      console.log(lis);
      var isWxMini = window.__wxjs_environment === "miniprogram";
      console.log(isWxMini);
      if (isWxMini) {
        lis.token = localStorage.getItem("Authorization");
        lis.ccisok = 1; //1:普通订单，2秒杀订单
        let payDataStr = JSON.stringify(lis);
        wx.miniProgram.navigateTo({
          url: "/pages/payputong/payputong?payDataStr=" + payDataStr, // 微信小程序调用支付的页面
        });
      } else {
        userModel.pay(lis).then((res) => {
          if (res.code == 200) {
            console.log(WeixinJSBridge);
            if (typeof WeixinJSBridge === "undefined") {
              // 微信浏览器内置对象。参考微信官方文档
              if (document.addEventListener) {
                document.addEventListener(
                  "WeixinJSBridgeReady",
                  _this.onBridgeReady(res.data),
                  false
                );
              } else if (document.attachEvent) {
                document.attachEvent(
                  "WeixinJSBridgeReady",
                  _this.onBridgeReady(res.data)
                );
                document.attachEvent(
                  "onWeixinJSBridgeReady",
                  _this.onBridgeReady(res.data)
                );
              }
            } else {
              console.log("准备调用微信支付");
              _this.onBridgeReady(res.data);
            }
          }
        });
      }
    },

    miaoshatijiao() {
      var _this = this;
      userModel.seckillOrder().then((res) => {
        if (res.code == 200) {
          var lis = {
            id: this.list.id,
            orderNumber: this.list.orderNumber,
            totalMoney: this.list.orderTotal,
            type: this.type,
            gid: this.list.gid,
          };
          var isWxMini = window.__wxjs_environment === "miniprogram";
          console.log(isWxMini);
          if (isWxMini) {
            lis.token = localStorage.getItem("Authorization");
            lis.ccisok = 2; //1:普通订单，2秒杀订单
            let payDataStr = JSON.stringify(lis);
            wx.miniProgram.navigateTo({
              url: "/pages/payputong/payputong?payDataStr=" + payDataStr, // 微信小程序调用支付的页面
            });
          } else {
            userModel.pay(lis).then((res) => {
              if (res.code == 200) {
                console.log(res);
                if (typeof WeixinJSBridge === "undefined") {
                  // 微信浏览器内置对象。参考微信官方文档
                  if (document.addEventListener) {
                    document.addEventListener(
                      "WeixinJSBridgeReady",
                      _this.onBridgeReady(res.data),
                      false
                    );
                  } else if (document.attachEvent) {
                    document.attachEvent(
                      "WeixinJSBridgeReady",
                      _this.onBridgeReady(res.data)
                    );
                    document.attachEvent(
                      "onWeixinJSBridgeReady",
                      _this.onBridgeReady(res.data)
                    );
                  }
                } else {
                  console.log("准备调用微信支付");
                  _this.onBridgeReady(res.data);
                }
              }
            });
          }
        }
      });
    },

    onBridgeReady(data) {
      console.log("调用微信支付WeixinJSBridge");
      var vm = this;
      WeixinJSBridge.invoke(
        "getBrandWCPayRequest",
        {
          // 下面参数内容都是后台返回的
          appId: data.appId, // 公众号名称，由商户传入
          timeStamp: data.timeStamp, // 时间戳
          nonceStr: data.nonceStr, // 随机串
          package: data.package, // 预支付id
          signType: data.signType, // 微信签名方式
          paySign: data.paySign, // 微信签名
        },
        function (res) {
          // 使用以上方式判断前端返回,微信团队郑重提示：res.err_msg将在用户支付成功后返回ok，但并不保证它绝对可靠。
          if (res.err_msg === "get_brand_wcpay_request:ok") {
            vm.$toast("支付成功");
            setTimeout(() => {
              vm.onLoad();
            }, 900);
          } else {
            vm.$toast("支付失败");
          }
        }
      );
    },
    //确认收货
    queren() {
      // const data = {
      //   id: this.id
      // };
      // orderModel
      //   .querenorder(data)
      //   .then(e => {
      //     // loading.clear();
      //     this.$toast("确认收货成功");
      //     this.onLoad();
      //   })
      //   .catch(() => loading.clear());
    },
    //取消订单
    quxiao() {
      const data = {
        id: this.id,
        type: this.type,
      };
      orderModel
        .quxiaoorder(data)
        .then((e) => {
          // loading.clear();
          this.$toast("订单取消成功");
          setTimeout(() => {
            this.onLoad();
          }, 800);
        })
        .catch(() => loading.clear());
    },
    onLoad() {
      console.log(this.yyisok);

      if (this.yyisok == null) {
        const data = {
          id: this.id,
          type: this.type,
        };

        const loading = this.$toast.loading({
          mask: true,
          duration: 0,
          message: "加载中...",
        });
        orderModel
          .dingdandetails(data)
          .then((e) => {
            loading.clear();
            console.log(e);
            this.list = e.data;
            if (e.data.carNo != "") {
              this.downloadData = {
                url:
                  "http://customer.car-posthouse.cn/quanbu_details.html?id=" +
                  e.data.id +
                  "&type=" +
                  this.type, //需要转化成二维码的网址
                icon: require("../../assets/img/1.png"), //二维码中间的图片,可以不设置
              };
            } else {
              this.downloadData = {
                url:
                  "http://customer.car-posthouse.cn/verification/?id=" +
                  e.data.id +
                  "&type=" +
                  e.data.type, //需要转化成二维码的网址
                icon: require("../../assets/img/1.png"), //二维码中间的图片,可以不设置
              };
            }

            let date = new Date().getTime();
            var time1 = e.data.createTime.replace(/-/g, "/");
            var date2 = new Date(time1).getTime() + 86400000;
            var staytimeGap = date2 - date;
            let stayHour = Math.floor(staytimeGap / (3600 * 1000));
            let leave1 = staytimeGap % (3600 * 1000);
            let stayMin = Math.floor(leave1 / (60 * 1000));
            let leave2 = leave1 % (60 * 1000);
            let staySec = Math.floor(leave2 / 1000);
            console.log(stayHour + "时" + stayMin + "分" + staySec + "秒");
            this.stayHour = stayHour;
            this.stayMin = stayMin;
            this.$nextTick(() => {
              this.initQRCode();
            });
          })
          .catch(() => loading.clear());
      } else {
        const data = {
          number: this.number,
        };

        const loading = this.$toast.loading({
          mask: true,
          duration: 0,
          message: "加载中...",
        });
        orderModel
          .yuyuenew(data)
          .then((e) => {
            loading.clear();
            console.log(e);
            this.list = e.data;
            this.downloadData = {
              url:
                "http://customer.car-posthouse.cn/quanbu_details.html?id=" +
                e.data.id +
                "&token=" +
                localStorage.getItem("Authorization") +
                "&type=" +
                this.type, //需要转化成二维码的网址
              icon: require("../../assets/img/1.png"), //二维码中间的图片,可以不设置
            };
            let date = new Date().getTime();
            var time1 = e.data.createTime.replace(/-/g, "/");
            var date2 = new Date(time1).getTime() + 86400000;
            var staytimeGap = date2 - date;
            let stayHour = Math.floor(staytimeGap / (3600 * 1000));
            let leave1 = staytimeGap % (3600 * 1000);
            let stayMin = Math.floor(leave1 / (60 * 1000));
            let leave2 = leave1 % (60 * 1000);
            let staySec = Math.floor(leave2 / 1000);
            console.log(stayHour + "时" + stayMin + "分" + staySec + "秒");
            this.stayHour = stayHour;
            this.stayMin = stayMin;
            this.$nextTick(() => {
              this.initQRCode();
            });
          })
          .catch(() => loading.clear());
      }
    },
    daohang() {
      // var latitude = 22.912715; // 纬度，浮点数，范围为90 ~ -90
      // var longitude = 123.456789; // 经度，浮点数，范围为180 ~ -180。
      // name: '黄江', // 位置名
      // address: '修理厂', // 地址详情说明
      var positionWd = Number(this.list.positionWd);
      var positionJd = Number(this.list.positionJd);
      console.log(positionJd);
      console.log(positionWd);
      wx.openLocation({
        latitude: positionWd, // 纬度，浮点数，范围为90 ~ -90
        longitude: positionJd, // 经度，浮点数，范围为180 ~ -180。
        name: this.list.garageName, // 位置名
        address: this.list.areaDetail, // 地址详情说明
        scale: 13, // 地图缩放级别,整形值,范围从1~28。默认为最大
        infoUrl: "", // 在查看位置界面底部显示的超链接,可点击跳转
      });
    },
    getConfig() {
      let that = this;
      userModel
        .config({ url: location.href.split("#")[0], gid: this.shangjia.id })
        .then((res) => {
          if (res.code == 200) {
            console.log(res);
            wx.config({
              debug: false, // 开启调试模式,调用的所有api的返回值会在客户端alert出来，若要查看传入的参数，可以在pc端打开，参数信息会通过log打出，仅在pc端时才会打印。
              appId: res.data.appid, // 必填，公众号的唯一标识
              timestamp: res.data.timestamp, // 必填，生成签名的时间戳
              nonceStr: res.data.noncestr, // 必填，生成签名的随机串
              signature: res.data.signature, // 必填，签名
              jsApiList: ["openLocation"], // 必填，需要使用的JS接口列表
            });
            wx.ready(() => {
              // wx.onMenuShareTimeline({
              //   title: that.list.garageName,
              //   link: link,
              //   imgUrl: "",
              //   success: function() {}
              // });
            });
          } else {
            this.$toast(res.data.msg);
          }
        });
    },
    QXyuyue() {
      let data = {
        id: this.list.id,
        type: this.list.type,
      };
      orderModel
        .QXappointment(data)
        .then((e) => {
          this.$toast("取消成功！");
          setTimeout(() => {
            this.$router.go(-1);
          }, 800);
        })
        .catch();
    },
    yuyue() {
      sessionStorage.removeItem("station");
      if (this.list.appointmentIds != null) {
        var station = {
          appointmentId: this.list.appointmentIds,
          itemName: [],
          comboItemList: [],
        };
        sessionStorage.setItem("station", JSON.stringify(station));
      }
      window.location.href =
        "/order_yuyue?orderNumber=" +
        this.list.orderNumber +
        "&carNo=" +
        this.list.carNo +
        "&type=" +
        this.list.type +
        "&rowtype=" +
        0;
    },
    pushHistory() {
      //写入空白历史路径
      let state = {
        title: "title",
        url: "#",
      };
      window.history.pushState(state, state.title, state.url);
    },

    backziding() {
      var _this = this;
      this.pushHistory();
      window.addEventListener(
        "popstate",
        function (e) {
          location.href = "/order?status=" + _this.row;
        },
        false
      );
    },
    //复制链接成功
    copy() {
      const input = document.createElement("input");
      document.body.appendChild(input);
      var http = this.list.orderNumber;
      input.setAttribute("value", http);
      input.select();
      if (document.execCommand("copy")) {
        document.execCommand("copy");

        this.$toast.success("复制成功！");
      }
      document.body.removeChild(input);
    },
    back() {
      //
      if (this.isok == null) {
        this.$router.push({
          path: "order",
          query: {
            status: this.row,
          },
        });
      } else {
        this.$router.go(-1);
      }
    },
    initQRCode() {
      // this.qrcode = new QRCode(this.$refs.qrCode, {
      //    text: "http://m.car-posthouse.cn/quanbu_details.html?gid=" + this.id, // 需要转换为二维码的内容
      //   width:260,
      //   height: 260,
      //   colorDark: "#000000",
      //   colorLight: "#ffffff",
      //   correctLevel: QRCode.CorrectLevel.H
      // });
    },
    creatQrCode() {
      // var id = 25;
      // var qrcode = new QRCode(this.$refs.qrCodeUrl, {
      //   text: "http://m.car-posthouse.cn/tuoke_initiation?gid=" + this.id, // 需要转换为二维码的内容
      //   width: 200,
      //   height: 200,
      //   colorDark: "#000000",
      //   colorLight: "#ffffff",
      //   correctLevel: QRCode.CorrectLevel.H
      // });
    },
  },
  created() {
    var isWxMini = window.__wxjs_environment === "miniprogram";
    this.isWxMini = isWxMini;
    this.id = utils.getUrlKey("id");
    this.status = utils.getUrlKey("status");
    this.type = utils.getUrlKey("type");
    this.row = utils.getUrlKey("row");
    this.number = utils.getUrlKey("number");
    this.yyisok = utils.getUrlKey("yyisok");
    this.getConfig();
    this.onLoad();
  },
  mounted() {
    this.isok = utils.getUrlKey("isok");
    console.log(this.isok);
    if (this.isok == null) {
      // console.log('asdsad')
      this.backziding();
    }
  },
};
</script>

<style lang="less" scoped>
// 标题
.biao_ti {
  width: 100%;

  text-align: center;
  color: #fff;
  z-index: 98;
  //   position: fixed;
  //   top: 0;
  //   left: 0;
  width: 100%;
}
.beijintu {
  width: 100%;
  position: absolute;

  top: 0;
  left: 0;
  z-index: 0;
}
.bianti_a {
  font-size: 16px;
  font-weight: bold;
  height: 46px;
  line-height: 46px;
  z-index: 99;
  position: fixed;
  left: 0;
  top: 0;
  width: 56px;
  width: 100%;
  text-align: center;
  color: #fff;
  overflow: hidden;
  background: url(../../assets/img/beijintiao.jpg) no-repeat;
  background-size: 100% 100%;
  //   background: #df3447;
}
.yyyue {
  float: right;
  color: red;
  font-size: 14px;
  font-weight: bold;
}
.zidong {
  text-align: left;
  //   position: absolute;
  z-index: 98;
  position: relative;
  margin-left: 15px;
  //   left: 0px;
  //   top: 0;
  margin-top: 20px;
}
.peisong {
  position: relative;

  border-radius: 5px;
  margin: 30px 15px 0px 15px;
  //   height: 100px;
  padding-bottom: 15px;
  background: #fff;
  text-align: left;
  color: #000;
  overflow: hidden;
  // width: 100%;
}
.bott {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 50px;
  background: #fff;
  font-size: 14px;
  z-index: 9999;
}
.zhifu {
  border: 1px solid red;
  padding: 2px 15px;
  border-radius: 17px;
  color: red;
  margin-top: 10px;
  margin-right: 15px;
  // font-weight: bold;
}
.quxiao {
  margin-top: 10px;
  border: 1px solid #666;
  padding: 2px 15px;
  border-radius: 17px;
  color: #333;
  margin-right: 15px;
  // font-weight: bold;
}
.peisong2 {
  position: relative;

  border-radius: 5px;
  margin: 10px 15px 0px 15px;
  //   height: 100px;
  //   padding-bottom: 15px;
  background: #fff;
  text-align: left;
  color: #000;
  overflow: hidden;
}
.dingdan_bianhao {
  overflow: hidden;
  position: relative;
  margin: 5px 15px 0px 15px;
  border-radius: 5px;
  background: #fff;
  font-size: 13px;
  padding: 12px 12px 0 12px;
}
.xian {
  border-bottom: 1px solid #f5f5f5;
}
.copi {
  min-width: 35px;
  height: 20px;
  font-size: 10px;
  color: #000;
  margin-left: 15px;
}
.din_a {
  padding: 8px 0px;
  line-height: 12px;
}
.wis {
  width: 50%;
  line-height: 35px;
  text-align: center;
}
.erdu {
  padding: 5px 0;
  overflow: hidden;
  line-height: 16px;
}
.lianxi {
  //   height: 25px;
  font-size: 13px;
  color: #666;
}
.moni {
  color: red;
  font-size: 15px;
}
.ff_c {
  color: #666;
}
.song {
  font-size: 14px;
  color: #000;
  vertical-align: middle;
  margin-left: 12px;
  font-weight: bold;
}
.song2 {
  font-size: 14px;
  color: #000;
  vertical-align: middle;
  margin-right: 3px;
  font-weight: bold;
  vertical-align: middle;
  line-height: 16px;
}
.anzhuang {
  height: 16px;
  vertical-align: middle;
}
.yichu_c {
  height: 30px;
  position: relative;
  font-size: 14px;
  width: 90%;
  overflow: hidden;
  display: -webkit-box;
  text-overflow: ellipsis;
  word-break: break-all;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  line-height: 16px;
}
.jianshu2 {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  line-height: 22px;
  //    margin-top: 4px;
}
.daohan {
  height: 20px;
  vertical-align: middle;
}
.lis_h1 {
  margin: 15px 12px 0px 12px;
  overflow: hidden;
}
.lis_h2 {
  margin: 0px 12px 10px 45px;
  font-size: 13px;
  font-weight: bold;
}
.lis_h3 {
  margin: 0px 12px 0px 45px;
  font-size: 13px;
  overflow: hidden;
}
.liss_c {
  margin: 20px 12px 20px 12px;
  font-size: 13px;
  overflow: hidden;
}
.lis_h4 {
  margin: 20px 12px 0px 12px;
  overflow: hidden;
}
.menbiao {
  width: 23px;
  height: 23px;
  vertical-align: middle;
}
.zidong_a {
  font-size: 17px;
  font-weight: bold;
}
.zidong_b {
  font-size: 13px;
}
.fanhui {
  line-height: 46px;
  padding: 0 10px;
  position: absolute;
  left: 0;
  top: 0;
}
.order_name {
  margin-left: 10px;
  height: 65px;
  position: relative;
  font-size: 13px;
  overflow: hidden;
  width: 74%;
  line-height: 16px;
}
.order_img {
  height: 60px;
  width: 60px;
}
.biaoti_ord {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.mad {
  margin: 15px 15px;
  border-radius: 5px;
  overflow: hidden;
  background: #fff;
  // height: 300px;
}
.qy_code_wrapper {
  // width: 80%;
  // margin: auto;
  text-align: center;
  padding: 15px 0;
}
.h1_c {
  font-size: 16px;
  margin-top: 15px;
  text-align: center;
}
.h1_d {
  font-size: 14px;
  // margin-top: 5px;
  text-align: center;
  color: #666;
}
.jiance {
  display: inline-block;
  background: #3e4799;
  color: #fff;
  font-weight: bold;
  font-size: 12px;
  // width: 73px;
  height: 20px;
  padding: 2px 4px;
  margin: 6px 0;
}
.ma {
  height: 150px;
}
.jianshu {
  color: #999;
  //   display: inline-block;
  //    margin-top: 4px;
}
//标题
.shuo_c span:nth-of-type(n + 2) {
  margin-left: 5px;
}
</style>
